import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { GOODS_REQUEST_NOTE } from '@utils/constants/goods-request/goods-request-note.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const goodsRequestApi = createApi({
    reducerPath: 'goodsRequestApi',
    tagTypes: ['GoodsRequest'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllGoodsRequest: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_first_name?: string;
                direct_sales_invoice_code?: string;
                direct_sales_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                customer_first_name,
                direct_sales_invoice_code,
                direct_sales_return_code,
            }) => {
                const queryParams = {
                    url: GOODS_REQUEST_NOTE.GET_ALL,
                    params: { page, limit },
                };

                if (customer_first_name) {
                    //@ts-ignore
                    queryParams.params.customer_first_name =
                        customer_first_name;
                }

                if (direct_sales_invoice_code) {
                    //@ts-ignore
                    queryParams.params.direct_sales_invoice_code =
                        direct_sales_invoice_code;
                }

                if (direct_sales_return_code) {
                    //@ts-ignore
                    queryParams.params.direct_sales_return_code =
                        direct_sales_return_code;
                }

                return queryParams;
            },
        }),
        getInvoice: builder.query<[], { invoiceCode: string | number }>({
            query: ({ invoiceCode }) => `${GOODS_REQUEST_NOTE.BASE_PATH}search/invoice?invoiceCode=${invoiceCode}`,
        }),
        

        getAllDirectMakePayments: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                direct_sales_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,

                direct_sales_return_code,
            }) => {
                const queryParams = {
                    url: GOODS_REQUEST_NOTE.GET_ALL_MAKE_PAYMENTS,
                    params: { page, limit },
                };

                if (direct_sales_return_code) {
                    //@ts-ignore
                    queryParams.params.direct_sales_return_code =
                        direct_sales_return_code;
                }

                return queryParams;
            },
        }),
        
        getGoodsRequest: builder.query<
            any,
            { goods_request_id: string | number }
        >({
            query: ({ goods_request_id }) =>
                `${GOODS_REQUEST_NOTE.GET_ONE}${goods_request_id}`,
        }),
        createGoodsRequest: builder.mutation({
            query: (body) => ({
                url: GOODS_REQUEST_NOTE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateGoodsRequest: builder.mutation({
            query: ({ id, body }) => ({
                url: `${GOODS_REQUEST_NOTE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        createDirectMakePayment: builder.mutation({
            query: ({ direct_sales_return_id, body }) => ({
                url: `${GOODS_REQUEST_NOTE.MAKE_PAYMENT}${direct_sales_return_id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteGoodsRequest: builder.mutation({
            query: ({ id, body }) => ({
                url: `${GOODS_REQUEST_NOTE.DELETE}${id}`,
                method: 'DELETE',
                body,
            }),
        }),
        deleteDirectMakePayment: builder.mutation({
            query: ({ direct_sales_return_payment_made_id, body }) => ({
                url: `${GOODS_REQUEST_NOTE.DELETE_MAKE_PAYMENT}${direct_sales_return_payment_made_id}`,
                method: 'DELETE',
                body,
            }),
        }),
    }),
});

export const {
    useGetAllGoodsRequestQuery,
    useGetGoodsRequestQuery,
    useCreateGoodsRequestMutation,
    useUpdateGoodsRequestMutation,
    useDeleteGoodsRequestMutation,
    useCreateDirectMakePaymentMutation,
    useGetAllDirectMakePaymentsQuery,
    useDeleteDirectMakePaymentMutation,
    useLazyGetInvoiceQuery
} = goodsRequestApi;
