import DeleteButton from '@components/buttons/DeleteButton';
import CustomInputField from '@components/form/CustomInputField';
import { calculatePercentage } from '@utils/general/cal-percent';

export const consignmentproductDetailColumns = (
    errors: any,
    register: any,
    setValue: any,
    handleRemove: any,
    control: any,
    muted?: boolean
) => {
    return [
        // {
        //     name: 'Product Code',
        //     maxWidth: '600px',
        //     width: '260px',
        //     selector: (row: any, index: number) => {
        //         return (
        //             <CustomInputField
        //                 classNames="w-[230px]"
        //                 noLabel
        //                 disabled
        //                 errors={errors}
        //                 name={`product_detail.${row.index}.product_code`}
        //                 register={register}
        //             />
        //         );
        //     },
        // },
        {
            name: 'Product Name',
            maxWidth: '600px',
            width: '260px',
            selector: (row: any, index: number) => {
                return (
                    <CustomInputField
                        classNames="w-[230px]"
                        noLabel
                        disabled
                        errors={errors}
                        name={`product_detail.${row.index}.product_name`}
                        register={register}
                    />
                );
            },
        },
        {
            name: 'Allocation Account Name',
            maxWidth: '600px',
            width: '260px',
            selector: (row: any, index: number) => {
                return (
                    <CustomInputField
                        classNames="w-[230px]"
                        noLabel
                        placeHolder=" "
                        disabled
                        errors={errors}
                        name={`product_detail.${row.index}.warehouse_name`}
                        register={register}
                    />
                );
            },
        },
        {
            name: 'Contract Qty',
            maxWidth: '600px',
            width: '120px',
            selector: (row: any, index: number) => {
                return (
                    <CustomInputField
                        min={0}
                        noLabel
                        // disabled={muted}
                        inputType="number"
                        placeHolder=" "
                        errors={errors}
                        name={`product_detail.${row.index}.contract_qty`}
                        register={register}
                        value={row?.contract_qty}
                        disabled
                    />
                );
            },
        },
        {
            name: 'Qty',
            maxWidth: '600px',
            width: '120px',
            selector: (row: any, index: number) => {
                return (
                    <CustomInputField
                        noLabel
                        // disabled={muted}
                        inputType="number"
                        errors={errors}
                        name={`product_detail.${row.index}.qty`}
                        register={register}
                        value={row?.qty}
                        min={1}
                        max={row?.contract_qty}
                    />
                );
            },
        },
        {
            name: 'Unit',
            maxWidth: '600px',
            selector: (row: any, index: number) => {
                return (
                    <CustomInputField
                        noLabel
                        disabled
                        errors={errors}
                        name={`product_detail.${row.index}.unit_name`}
                        register={register}
                    />
                );
            },
        },
        {
            name: 'Sale Price',
            maxWidth: '600px',
            selector: (row: any, index: number) => {
                return (
                    <CustomInputField
                        noLabel
                        disabled
                        errors={errors}
                        name={`product_detail.${row.index}.sales_price`}
                        register={register}
                    />
                );
            },
        },
        {
            name: 'Amount',
            maxWidth: '600px',
            selector: (row: any, index: number) => {
                return (
                    <CustomInputField
                        noLabel
                        disabled
                        setValue={setValue}
                        errors={errors}
                        name={`product_detail.${row.index}.amount`}
                        register={register}
                        value={row?.amount || row?.sales_price * row?.qty}
                    />
                );
            },
        },
        {
            name: 'Discount',
            maxWidth: '600px',
            width: '250px',
            selector: (row: any, index: number) => {
                const discountValue =
                    row.discount_type === 'AMT'
                        ? row?.discount_amount
                        : calculatePercentage(
                              row?.sales_price * row?.qty,
                              row?.discount_amount
                          );

                return (
                    <div className="flex items-center">
                        <CustomInputField
                            disabled={muted}
                            inputType="number"
                            classNames="-mr-3 w-[130px]"
                            noLabel
                            errors={errors}
                            name={`product_detail.${row.index}.discount`}
                            register={register}
                            defaultValue={discountValue}
                            value={row?.discount}
                        />
                        <select
                            value={row?.discount_type || 'AMT'}
                            disabled={muted}
                            onChange={(e) =>
                                setValue(
                                    `product_detail.${row.index}.discount_type`,
                                    e.target.value
                                )
                            }
                            className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                        >
                            <option value="AMT">AMT</option>
                            <option value="%">%</option>
                        </select>
                    </div>
                );
            },
        },
        {
            name: 'Tax',
            maxWidth: '600px',
            width: '250px',
            selector: (row: any, index: number) => {
                const taxValue =
                    row.tax_type === 'AMT'
                        ? row?.tax_amount
                        : calculatePercentage(
                              row?.sales_price * row?.qty,
                              row?.tax_amount
                          );

                return (
                    <div className="flex items-center">
                        <CustomInputField
                            disabled
                            key={`product_detail.${row.index}.tax`}
                            inputType="number"
                            classNames="-mr-3 w-[130px]"
                            noLabel
                            errors={errors}
                            name={`product_detail.${row.index}.tax`}
                            register={register}
                            defaultValue={taxValue}
                            value={row?.tax}
                        />
                        <select
                            value={row?.tax_type || 'AMT'}
                            disabled
                            onChange={(e) =>
                                setValue(
                                    `${row.index}.tax_type`,
                                    e.target.value
                                )
                            }
                            className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                        >
                            <option value="AMT">AMT</option>
                            <option value="%">%</option>
                        </select>
                    </div>
                );
            },
        },
        {
            name: 'Total Amount',
            maxWidth: '600px',
            selector: (row: any, index: number) => {
                return (
                    <CustomInputField
                        setValue={setValue}
                        noLabel
                        disabled
                        errors={errors}
                        name={`product_detail.${row.index}.total_amount`}
                        register={register}
                        value={row?.total_amount}
                    />
                );
            },
        },
        {
            name: 'Action',
            // right: 'true',
            selector: (row: any, index: number) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <DeleteButton
                        disabled={muted}
                        id={row.id}
                        actionHandler={() => handleRemove(row.index)}
                    />
                </div>
            ),
        },
    ];
};

export const consignmentproductDetailColumnNames = [
    'Product Code',
    'Product Name',
    'Unit',
    'Sale Price',
    'Qty',
    'Amount',
    'Discount',
    'Tax',
    'Total Amount',
    'Action',
];
