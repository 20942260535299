import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { SECONDARY_INVOICE } from '@utils/constants/secondary-sales-invoice.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const secondaryInvoiceApi = createApi({
    reducerPath: 'secondaryInvoiceApi',
    tagTypes: ['SecondaryInvoice'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllSecondaryInvoices: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                secondary_sales_invoice_code?: string;
            }
        >({
            query: ({ page = 1, limit = 10, secondary_sales_invoice_code }) => {
                const queryParams = {
                    url: SECONDARY_INVOICE.GET_ALL,
                    params: { page, limit },
                };

                if (secondary_sales_invoice_code) {
                    //@ts-ignore
                    queryParams.params.secondary_sales_invoice_code =
                        secondary_sales_invoice_code;
                }

                return queryParams;
            },
        }),
        getSecondaryInvoice: builder.query<
            any,
            { secondary_sales_invoice_id: string | number }
        >({
            query: ({ secondary_sales_invoice_id }) => {
                return `${SECONDARY_INVOICE.GET_ONE}${secondary_sales_invoice_id}`;
            },
        }),
        createSecondaryInvoice: builder.mutation({
            query: ({ id, body }) => ({
                url: `${SECONDARY_INVOICE.CREATE}`,
                method: 'POST',
                body,
            }),
        }),
        updateSecondaryInvoice: builder.mutation({
            query: ({ id, body }) => ({
                url: `${SECONDARY_INVOICE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteSecondaryInvoice: builder.mutation({
            query: (id) => ({
                url: `${SECONDARY_INVOICE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllSecondaryInvoicesQuery,
    useGetSecondaryInvoiceQuery,
    useCreateSecondaryInvoiceMutation,
    useUpdateSecondaryInvoiceMutation,
    useDeleteSecondaryInvoiceMutation,
    useLazyGetSecondaryInvoiceQuery,
} = secondaryInvoiceApi;
